import React from "react";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";
import Parser from 'html-react-parser';
import {truncateText} from "../utils/wordsLimit";
import ContactModal from "./ContactModal";

export default function SessionCard({ session }) {

    const sessionId = `${session.id}`;
    const truncatedTitle = truncateText(session.titre, 50, 12,  2);
    const truncatedDescription = truncateText(session.intro, 35 ,  25, 2);
    return (
            <motion.div
                    className="job-block col-lg-6 col-md-12 col-sm-12"
                    layout
                    initial={{ opacity: 0.6, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
            >
                    <div className="inner-box">
                        <div className="content d-flex">
                            <div className="date">
                                {session.jour}
                                <span>{session.moisAb}</span>
                            </div>
                            <h4 className="session-card">
                                <Link to={`/nos-formations/${session.idModule}/${session.slugModule}`} title={session.titre}>
                                    {truncatedTitle && Parser(truncatedTitle)} <br/>
                                </Link>
                            </h4>
                            <span className="company-logo">
                                {session?.image? <img src={session.image['lien']} alt={session.image['nom']} style={{borderRadius: "18px"}} /> : ''}
                            </span>
                        </div>

                        <div className="session-card-description" style={{lineHeight: "22px"}}>{truncatedDescription && Parser(truncatedDescription)}</div>
                        <div className="session-card-bottom">
                            <div className="d-flex gap-2">
                                <img src="/images/icons/clock.png" alt="icon" style={{width: "28px", height: "28px" }}/>
                                <b>{session.Heure}</b>
                            </div>
                            <motion.a
                                    href={`/nos-formations/${session.idModule}/${session.slugModule}`}
                                    className="theme-btn btn-style-one small"
                                    initial={{ opacity: 0, y: 0 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.5, duration: 0.5 }}
                            >
                                Découvrir la formation
                            </motion.a>
                            <motion.div
                                    initial={{ opacity: 0, y: 0 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.5, duration: 0.5 }}
                            >
                                <ContactModal sessionId={sessionId} sessionDate={session.dateHeure} sessionName={session.titre} type={2} textButton="Cette session m'intéresse" small="small" />
                            </motion.div>
                        </div>
                    </div>
            </motion.div>
    );
}
