import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Loader = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
            <AnimatePresence>
                {isLoading && (
                        <motion.div
                                className="loader"
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                        >
                            <motion.div
                                    className="loader__logo"
                                    initial={{ opacity: 1 }}
                                    animate={{ opacity: 0 }}
                                    transition={{ delay: 2.5, duration: 0.5 }}
                            />
                            <motion.div
                                    className="loader__background loader__background--white"
                                    exit={{ y: '-100%' }}
                                    transition={{ duration: 0.5 }}
                            />
                            <motion.div
                                    className="loader__background loader__background--green"
                                    initial={{ y: '100%' }}
                                    exit={{ y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.1 }}
                            />
                            <motion.div
                                    className="loader__background loader__background--blue"
                                    initial={{ y: '100%' }}
                                    exit={{ y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.2 }}
                            />
                        </motion.div>
                )}
            </AnimatePresence>
    );
};

export default Loader;