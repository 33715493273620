import React, { useState, useEffect } from "react";
import FooterDefault from "../../components/footer/common-footer";
import MobileMenu from "../../components/header/MobileMenu";
import Seo from "../../components/common/Seo";
import {useParams} from 'react-router-dom';
import Parser from 'html-react-parser';
import Header from "../../components/home-7/Header";
import Hero7 from "../../components/hero/hero-7";
import {motion} from "framer-motion";
import Sidebar1 from "../../components/accueil/sidebar1";
import Sidebar2 from "../../components/accueil/sidebar2";
import ModalJeu from "../../components/form/ModalJeu";

const JeuSinglePage = () => {
    const { jeuId, jeuSlug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [data, setData] = useState();
    const [page, setPage] = useState();
    const [games, setGames] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    useEffect(() => {
        const loadTimeout = setTimeout(() => {
            setIsPageLoaded(true);
        }, 2000);

        return () => clearTimeout(loadTimeout);
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/jeu_programmes/${jeuId}`)
                .then((res) => res.json())
                .then((response) => {
                    setData(response);
                    setIsLoading(false);
                })
                .catch((error) => console.log(error));
    }, [jeuId, jeuSlug]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/jeu_programmes?type=1`)
                .then((res) => res.json())
                .then((response) => {
                    setGames(response);
                })
                .catch((error) => console.log(error));
    }, []);

    const entrepriseId = data?.jeu_entreprise.id;
    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/jeu_entreprises/${entrepriseId}`)
                .then((res) => res.json())
                .then((response) => {
                    setPage(response);
                    if (response.erreur === 1) {
                        window.location.replace("/");
                    }
                    setIsLoading(false);
                })
                .catch((error) => console.log(error));
    }, [entrepriseId]);

    useEffect(() => {
        if (!isLoading && isPageLoaded) {
            const links = document.querySelectorAll('a[href="/jeu-inclusion/equipe"]');
            links.forEach(link => {
                link.addEventListener('click', handleShowModal);
                link.removeAttribute('href');
            });

            return () => {
                links.forEach(link => {
                    link.removeEventListener('click', handleShowModal);
                });
            };
        }
    }, [isLoading, isPageLoaded]);

    if (!data || !games.length) {
        return <div>Loading...</div>;
    }

    const currentIndex = games.findIndex(game => game.id === parseInt(jeuId));

    const prevIndex = currentIndex > 0 ? currentIndex - 1 : null;
    const nextIndex = currentIndex < games.length - 1 ? currentIndex + 1 : null;

    return (
            <>
                <Seo pageTitle={data?.nom} />

                {/* <!-- Header Span --> */}
                <span className="header-span"></span>

                <Header />
                {/* <!--End Main Header --> */}

                <MobileMenu />
                {/* End MobileMenu */}

                {/* <!-- Job Detail Section --> */}

                {isPageLoaded && (
                        <>
                            <div>
                                <Hero7 key={page.id}
                                       titre={page?.nom}
                                       description={page?.introduction}
                                       image={page?.oneHeader?.lien}
                                       nomImage={page?.oneHeader?.nom}
                                       filArian={false}
                                />
                                <motion.section
                                        className="libre job-detail-outer jeu"
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        transition={{delay: 0.5, duration: 1}}
                                >
                                    <div className="auto-container">
                                        <div className="row">
                                            <div className="content-container d-flex flex-wrap">

                                                <div
                                                        className="libre-sidebar content-column col-lg-9 col-md-12 col-sm-12">
                                                    <div className="d-flex justify-content-between"
                                                         style={{textAlign: 'left', padding: '0px 50px 0 0'}}>
                                                        <div className="page-left">

                                                            {page?.description && (
                                                                    <div className="text mt-9">
                                                                        <p>{Parser(page?.description)}</p>
                                                                    </div>
                                                            )}
                                                            <div className="text mt-9">
                                                                <h3>{data.nom}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sidebar-column col-lg-3 col-md-12 col-sm-12">
                                                    <aside className="sidebar">
                                                        <Sidebar1
                                                                image={page?.oneLogo.lien ? page?.oneLogo.lien : null}
                                                                imageAlt={page?.oneLogo.nom ? page?.oneLogo.nom : null}
                                                                title="Engagez-vous !"
                                                                description={page?.descriptionSideBar1}
                                                                background="#F7FEF6"
                                                        />

                                                        <Sidebar2
                                                                title="Guide d'utilisation"
                                                                description={page?.descriptionSideBar2}
                                                                guide={true}
                                                        />

                                                    </aside>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </motion.section>
                            </div>
                        </>
                )}
                {!isLoading && isPageLoaded && (
                        <section className="blog-single" style={{paddingTop: "0"}}>
                            <div className="auto-container">
                                <div className="row">
                                    <div className="content-container d-flex flex-wrap">
                                        <div
                                                className="libre-sidebar content-column col-lg-12 col-md-12 col-sm-12">

                                            {data?.iframeJeu && (
                                                    <figure className="main-image">
                                                        <div style={{width: '100%'}}>
                                                            <div style={{
                                                                position: 'relative',
                                                                paddingBottom: '56.25%',
                                                                paddingTop: 0,
                                                                height: 0
                                                            }}>
                                                                <iframe
                                                                        title="J1"
                                                                        width="1200"
                                                                        height="675"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            left: 0,
                                                                            width: '100%',
                                                                            height: '100%'
                                                                        }}
                                                                        src={data.iframeJeu}
                                                                        type="text/html"
                                                                        allowFullScreen={true}
                                                                ></iframe>
                                                            </div>
                                                        </div>
                                                    </figure>
                                            )}
                                            <div style={{textAlign: "center"}}>
                                                <motion.a
                                                        href={`/start/${page?.id}/${page?.slug}`} title={page.nom}
                                                        className="theme-btn btn-style-six"
                                                        initial={{ opacity: 0, y: 0 }}
                                                        animate={{ opacity: 1, y: 20 }}
                                                        transition={{ delay: 1.0, duration: 1.5 }}
                                                >
                                                    Retour aux étapes du Jeu
                                                </motion.a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                )}
                {/* <!-- End Job Detail Section --> */}

                {isPageLoaded && (
                        <FooterDefault footerStyle="style-six alternate -type-11"/>
                )}
                {/* <!-- End Main Footer --> */}
                <ModalJeu jeuId={page?.id} handleClose={handleCloseModal} show={showModal} newCode={true} />
            </>
    );
};

export default JeuSinglePage;