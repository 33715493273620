import React from 'react';
import Seo from "../components/common/Seo";
import Header from "../components/home-7/Header";
import MobileMenu from "../components/header/MobileMenu";
import FooterDefault from "../components/footer/common-footer";
import mobileMenuData from "../data/mobileMenuData";
import {NavLink} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getProducts} from "../lib/product";

function SiteMap() {
    const { data: products } = useQuery({
        queryKey: ["products"],
        queryFn: getProducts,
    });
    const titleArian = (title) => { return (title).charAt(0).toUpperCase() + (title || '').slice(1).toLowerCase();};
    return (
            <>
                <Seo pageTitle="Pourquoi nous choisir" />
                <span className="header-span"></span>
                <Header />
                <MobileMenu />

                <section className="content-elements">
                    <div className="auto-container">
                        <h1>Plan du site</h1>
                        <div className="row">
                            <div className="col-lg-6">
                                <h4>Toutes les pages</h4>
                                <ul className="list-style-three">
                                    {mobileMenuData.map((item) => (
                                        item.items && item.items.length > 0 ? (
                                            <li
                                                key={item.id}
                                            >
                                                <span>{item.label}</span>
                                                <ul>
                                                    {item.items.map((subItem) => (
                                                        <li
                                                            key={subItem.routePath}
                                                        >
                                                            <NavLink to={subItem.routePath}>{subItem.name}</NavLink>
                                                        </li>
                                                    ))}
                                                </ul>

                                            </li>
                                        ) : (
                                            <li
                                                key={item.id}
                                            >
                                                <NavLink to={item.routePath}>
                                                    {item.label}
                                                </NavLink>
                                            </li>
                                        )
                                    ))}
                                </ul>


                            </div>
                            <div className="col-lg-6">
                                <h4>Formations</h4>
                                <ul className="list-style-three">
                                    {products?.map((product) => (
                                            <li
                                                key={product.id}
                                            >
                                                <NavLink to={`/nos-formations/${product.id}/${product.slug}`}>
                                                    {titleArian(product.titre)}
                                                </NavLink>
                                            </li>

                                    ))}
                                </ul>


                            </div>
                        </div>
                    </div>
                </section>

                <FooterDefault footerStyle="style-six alternate -type-11"/>
            </>
    );
};

export default SiteMap;