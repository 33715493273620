import React, { useState, useCallback, useMemo } from 'react';
import Parser from 'html-react-parser';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

const CLASS_NAMES = {
    ACTIVE: 'active',
    ACTIVE_BLOCK: 'active-block',
    CURRENT: 'current',
};

function FAC({ data, title, description }) {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = useCallback((index) => {
        setActiveIndex(prevIndex => prevIndex === index ? null : index);
    }, []);

    const parsedTitle = useMemo(() => title ? Parser(title) : null, [title]);
    const parsedDescription = useMemo(() => description ? Parser(description) : null, [description]);

    return (
            <section className="faqs-section">
                <div className="auto-container">
                    <div className="text-center">
                        {parsedTitle && <h2 className="libre-title mb-5">{parsedTitle}</h2>}
                        {parsedDescription && <div className="text mt-9">{parsedDescription}</div>}
                    </div>
                    <motion.ul className="accordion-box" initial={false}>
                        {data?.map((content, contentIndex) => (
                                <motion.li
                                        layout
                                        initial={{ opacity: 0, scale: 0.95 }}
                                        whileInView={{ opacity: 1, scale: 1 }}
                                        viewport={{ once: true }}
                                        transition={{ duration: 0.3, delay: contentIndex * 0.1 }}
                                        className={`accordion block ${activeIndex === contentIndex ? CLASS_NAMES.ACTIVE_BLOCK : ''}`}
                                        key={contentIndex}
                                >
                                    <motion.div
                                            className={`acc-btn ${activeIndex === contentIndex ? CLASS_NAMES.ACTIVE : ''}`}
                                            onClick={() => handleClick(contentIndex)}
                                            layout="position"
                                    >
                                        {content.titre && typeof content.titre === 'string' && Parser(content.titre)}
                                        <span
                                                className="icon flaticon-add"
                                        />
                                    </motion.div>
                                    <AnimatePresence initial={false}>
                                        {activeIndex === contentIndex && (
                                                <motion.div
                                                        key="content"
                                                        initial="collapsed"
                                                        animate="open"
                                                        exit="collapsed"
                                                        variants={{
                                                            open: { opacity: 1, height: "auto" },
                                                            collapsed: { opacity: 0, height: 0 }
                                                        }}
                                                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                        className={`acc-content ${CLASS_NAMES.CURRENT}`}
                                                >
                                                    <motion.div
                                                            className="content"
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            transition={{ duration: 0.3, delay: 0.2 }}
                                                    >
                                                        {content.description && typeof content.description === 'string' && (
                                                                <h4 className="counter-title">
                                                                    {Parser(content.description)}
                                                                </h4>
                                                        )}
                                                    </motion.div>
                                                </motion.div>
                                        )}
                                    </AnimatePresence>
                                </motion.li>
                        ))}
                    </motion.ul>
                </div>
            </section>
    );
}

FAC.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        titre: PropTypes.string,
        description: PropTypes.string,
    })),
    title: PropTypes.string,
    description: PropTypes.string,
};

export default FAC;