import React, {useEffect, useState} from "react";
import Seo from "../components/common/Seo";
import MobileMenu from "../components/header/MobileMenu";
import FormModel from "../components/form/FormModel";
import Header from "../components/home-7/Header";
import FooterDefault from "../components/footer/common-footer";
import axios from "axios";
import {motion} from "framer-motion";
import Parser from "html-react-parser";

const DemanderUnDevis = () => {
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/front_pages?type=7');
                setPages(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getRandomImage = (images) => {
        const randomIndex = Math.floor(Math.random() * images.length);
        return images[randomIndex];
    };

    const containerIndex = 0;

    const animations = {
        image: {
            initial: { opacity: 0, y: 0 },
            animate: { opacity: 1, y: 0 },
            transition: { duration: 0.5 },
        },
        form: {
            initial: { opacity: 0, x: 0 },
            animate: { opacity: 1, x: 0 },
            transition: { duration: 1.5 },
        },
    };

    return (
            <>
                <Seo pageTitle="Demander une présentation" />
                <span className="header-span"></span>
                <Header />
                <MobileMenu />
                {!loading && (
                        <section className="contact-section">
                            {pages?.map((home, homeIndex) => (
                                    <React.Fragment key={homeIndex}>
                                        {home?.frontPageContainers?.map((container, containerIndex) => (
                                                <div key={containerIndex}>
                                                    {container?.libre && (
                                                            <React.Fragment key={`banner-${containerIndex}`}>
                                                                {container.frontPageContents?.map((content, contentIndex) => (
                                                                        <div key={contentIndex} className="d-flex justify-content-between">
                                                                            {content?.image?.length > 0 ? (
                                                                                    <motion.img
                                                                                            src={getRandomImage(content.image).urlS3}
                                                                                            alt=""
                                                                                            initial="initial"
                                                                                            animate="animate"
                                                                                            variants={animations.image}
                                                                                    />
                                                                            ) : ''}

                                                                            <motion.div
                                                                                    className="contact-form default-form"
                                                                                    initial="initial"
                                                                                    animate="animate"
                                                                                    variants={animations.form}
                                                                            >
                                                                                {content.titre &&<h2 className="shadow-title center">{Parser(content.titre)}</h2>}
                                                                                {content.description &&<div className="text text-center mt-9">{Parser(content.description)}</div>}
                                                                                <div className="mb-5"></div>
                                                                                <FormModel type={1} />
                                                                            </motion.div>
                                                                        </div>
                                                                ))}
                                                            </React.Fragment>
                                                    )}
                                                </div>
                                        ))}
                                    </React.Fragment>
                            ))}
                        </section>
                )}
                <FooterDefault footerStyle="style-six alternate -type-11" />
            </>
    );
};


export default DemanderUnDevis;

