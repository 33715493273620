import React from 'react';
import Parser from 'html-react-parser';
import ContactModal from "../../ContactModal";
import {truncateText} from "../../../utils/wordsLimit";
import Breadcrumb from "../../common/Breadcrumb";

function Hero7({ titre, description, image, nomImage, btn, url, formationId, lienFicheFormation, formationArian, formationTitleArian, filArian = true }) {

    const truncatedIntro = truncateText(description, 200, 100, 2);
    //const truncatedTitre = truncateText(titre, 150, 8);

    return (
            <>
                <section className="banner-section-three -type-12">
                    <div className="bg-image">

                        <figure className="main-image wow fadeInRight" data-wow-delay="1500ms">
                            {image ? (<img src={image} alt={nomImage} />) : ''}
                        </figure>
                        <div className="image-voile" style={{display: "none"}}></div>
                    </div>

                    <div className="auto-container">
                        <div className="row">
                            <div className="content-column col-lg-9 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="title-box wow fadeInUp">
                                        {filArian ? (<Breadcrumb meta={formationTitleArian} formation={formationArian} />):""}

                                        <h1 title={titre}>
                                            {typeof titre === 'string' ? Parser(titre) : ''}
                                            {/*{typeof content.titre === 'string' ? Parser(content.titre) : ''}*/}
                                        </h1>
                                        <div className="text" style={{color: "#363636"}}>
                                            {/*{typeof content.description === 'string' ? (truncatedIntro && Parser(truncatedIntro)): ''}*/}
                                            {typeof description === 'string' ? Parser(truncatedIntro) : ''}
                                        </div>
                                    </div>
                                    <div className="session-card-bottom mt-3">
                                        {formationId ? (
                                            <>
                                            {lienFicheFormation ? (
                                                <a href={url} className="theme-btn btn-style-one">Téléchargez la fiche formation</a>): ''}
                                                <ContactModal formationId={formationId} formationName={titre} type={1} textButton="Recevoir des informations"/>
                                            </>
                                        ): ''}
                                        {btn ? (
                                            <>
                                                <a href={url} className="theme-btn btn-style-one">{btn}</a>
                                            </>
                                        ): ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
    );

}

export default Hero7;
