import React from "react";
import Seo from "../components/common/Seo";
import Breadcrumb from "../components/common/Breadcrumb";
import MobileMenu from "../components/header/MobileMenu";
import QuiSommesAPi from "./quiSommesNous/about";
import Header from "../components/home-7/Header";
import FooterDefault from "../components/footer/common-footer";

const About = () => {
    return (
            <>
                <Seo pageTitle="Qui sommes-nous" />
                <span className="header-span"></span>
                <Header />
                <MobileMenu />
                <Breadcrumb title="Qui sommes-nous" meta="Qui sommes-nous" />
                <QuiSommesAPi />
                <FooterDefault footerStyle="style-six alternate -type-11" />
            </>
    );
};


export default About;

