import React from 'react';
import Parser from 'html-react-parser';
import {AnimatePresence} from "framer-motion";
import ProductCard from "../ProductCard";

const FormationSection = ({ container, formationData, containerIndex, nbrItem, background }) => {
    const bgStyle = background ? { background } : {};
    if (!container?.formation) return null;

    return (
            <React.Fragment key={`banner-${containerIndex}`}>
                <section className={nbrItem === '4' ? "items4 layout-pt-60 layout-pb-60" : "layout-pt-60 layout-pb-60"} style={bgStyle}>
                    <div className="auto-container">
                        <div className="row justify-content-between align-items-end">
                            {container.titre && <h2 className="shadow-title blue">{Parser(container.titre)}</h2>}
                            {container.description && <div className="text mt-15 text-center">{Parser(container.description)}</div>}
                        </div>

                        <div className="row grid-base pt-50 wow fadeInUp justify-content-center">
                            {formationData?.slice(0, 4).map((product) => (
                                <AnimatePresence key={product.id}>
                                    <ProductCard product={product} colLg="3" />
                                </AnimatePresence>
                            ))}
                        </div>
                        <a href="/nos-formations" className="theme-btn btn-style-six mt-5" style={{ margin: 'auto', display: 'block', width: 'fit-content' }}>
                            Découvrez nos formations
                        </a>
                    </div>
                </section>
            </React.Fragment>
    );
};

export default FormationSection;