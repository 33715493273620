import React from 'react';
import { motion } from 'framer-motion';
import Parser from 'html-react-parser';

function Main2({ image, title, description, url, button, background }) {
    const bgStyle = background ? { background } : {};
    return (
            <section className="about-section-two style-two layout-pt-60 layout-pb-60 js-tab-menu-content" style={bgStyle}>
                <div className="auto-container">
                    <div className="row grid-base align-items-center g-5">
                        <div className="content-column -no-margin col-xl-5 col-lg-6 col-md-12 col-sm-12 wow fadeInRight">
                            <div className="wow fadeInLeft">
                                <div className="sec-title">
                                    {title && (<h2 className="fw-700">{typeof title === 'string' ? Parser(title) : ''}</h2>)}
                                </div>
                                <div className="text">
                                    {description && (<div className="text mt-30">{typeof description === 'string' ? Parser(description) : ''}</div>)}
                                </div>
                                {button && (
                                        <motion.a
                                                href={url}
                                                className="theme-btn btn-style-six"
                                                initial={{ opacity: 0, y: 0 }}
                                                animate={{ opacity: 1, y: 20 }}
                                                transition={{ delay: 1.0, duration: 1.5 }}
                                        >
                                            {button}
                                        </motion.a>
                                )}
                            </div>
                        </div>
                        {image && (
                                <div className="image-column col-xl-5 offset-xl-2 col-lg-5 offset-lg-1 col-md-12 col-sm-12">
                                    <figure className="image-box">
                                        {image && <img src={image.urlS3} alt={image.alt} style={{borderRadius: "25px"}} />}
                                    </figure>
                                </div>
                        )}
                    </div>
                </div>
            </section>
    );
}

export default Main2;
