import React, { useState, useEffect } from "react";
import FooterDefault from "../../components/footer/common-footer";
import MobileMenu from "../../components/header/MobileMenu";
import Seo from "../../components/common/Seo";
import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import Header from "../../components/home-7/Header";
import Hero7 from "../../components/hero/hero-7";
import { Gallery } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import Tabs from "../../components/formation-single-pages/Tabs";
import FormationPageSidebar from "../../components/formation-single-pages/Sidebar";
import CTAPageFormation from "../../components/formation-single-pages/CTA";
import CalendrierSessions from "../../components/formation-single-pages/CalendrierSessions";
import Ressources from "../../components/accueil/ressources";
import FormationSection from "../../components/accueil/formations";
import axios from "axios";
import Libre from "../../components/accueil/libre";
import {htmlToText} from "html-to-text";
import Main1 from "../../components/accueil/main1";
import Main2 from "../../components/accueil/main2";

const FormationSinglePage = () => {
    const { formationId, formationSlug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        const loadTimeout = setTimeout(() => {
            setIsPageLoaded(true);
        }, 1000);

        return () => clearTimeout(loadTimeout);
    }, []);

    useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + `/front_formations/${formationId}`)
            .then((res) => res.json())
            .then((response) => {
              setData(response);
              setIsLoading(false);
            })
            .catch((error) => console.log(error));
    }, [formationId, formationSlug]);

    const [pageFormationData, setPageFormation] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/front_pages?type=14`);
                setPageFormation(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    const cleanHTML = (html) => {
        // Ajoute la classe "hidden-paragraph" aux balises <p> pour les masquer
        const cleanedHTML = html
                .replace(/<p>/g, '<p class="hidden-paragraph">')
                .replace(/<ul>/g, '<ul class="list-style-three">')
                .replace(/\n/g, '') // Supprime les sauts de ligne
                .replace(/\r/g, '') // Supprime les retours chariot
                .trim(); // Supprime les espaces en début et fin de chaîne

        const styledHTML = `<div style="text-align: left;">${cleanedHTML}</div>`;

        return Parser(styledHTML);
    };
    const seoPageDescription = (html) => {
        return htmlToText(html).slice(0, 150);
    };
    const titleArian = (title) => { return (title).charAt(0).toUpperCase() + (title || '').slice(1).toLowerCase();};
  return (
    <>
        <Seo
            pageTitle={
                (data?.titre || '')
                    .charAt(0)
                    .toUpperCase() + (data?.titre || '')
                    .slice(1)
                    .toLowerCase()
            }
            pageDescription={
                data && data.introduction
                    ? seoPageDescription(data.introduction)
                    : ''
            }
        />
      <span className="header-span"></span>

      <Header />

      <MobileMenu />

      {!isLoading && (
      <>
          <Hero7 key={data.id}
                 titre={data?.titre}
                 description={data?.description}
                 image={data?.oneImagePrincipale.lien }
                 nomImage={data?.oneImagePrincipale.nom}
                 url={data?.url}
                 formationArian = {true}
                 formationTitleArian = {titleArian(data?.titre)}
                 formationId={formationId}/>

          <section className="candidate-detail-section layout-pt-40">
              <div className="candidate-detail-outer">
                  <div className="auto-container">
                      <div className="row">
                          <div className="content-column col-lg-8 col-md-12 col-sm-12">
                              <div className="text">
                                  <div className="mb-4">
                                      {data && data.introduction ? (
                                          <>
                                              <h2 className="shadow-title left">Introduction</h2>
                                              {cleanHTML(data.introduction)}
                                          </>
                                      ) : ''}
                                  </div>

                                  {data && data.oneImage ? (
                                  <div className="video-outer">
                                      <div className="video-box">
                                          <figure className="image">
                                              <div className="play-now">
                                                  <img src={data.oneImage.lien} alt={data.oneImage.nom} style={{borderRadius: "30px"}}/>
                                              </div>
                                          </figure>
                                      </div>
                                  </div>
                                  ): ''}

                                  {data && data.objectifs ? (
                                  <div className="mb-4">
                                      <>
                                          <h2 className="shadow-title left">Objectifs</h2>
                                          {cleanHTML(data.objectifs)}
                                      </>
                                  </div>
                                  ) : ''}

                                  {data?.frontChapitres && data.frontChapitres.length > 0 && (
                                    <div className="mb-4">
                                      <h2 className="shadow-title left">Plan de la formation</h2>
                                      {data.frontChapitres.map((chap) => (
                                        <div key={chap.id}>
                                          {chap.description ? (
                                            <div style={{ marginLeft: "0px" }}>
                                              <img src="/images/icons/bullet.png" style={{ width: "12px", marginRight : "14px" }}/>
                                                <span>{chap.titre}</span>

                                            </div>
                                          ) : (
                                              <div className="mt-2 mb-2">
                                                <strong style={{ fontSize: "20px", fontWeight: "700", lineHeight: "30px" }}>
                                                  {chap.titre}
                                                </strong>
                                              </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  )}

                                  {data && data.images ? (
                                      <div className="job-detail">
                                          <div className="row images-outer">
                                              <Gallery>
                                                  {data.images.map((img, index) => (
                                                      <div className="col-lg-3 col-md-3 col-sm-6" key={index}>
                                                          <figure className="image" role="button">
                                                              <div className="">
                                                                  <img
                                                                      width={190}
                                                                      height={167}
                                                                      src={img.lien}
                                                                      alt={img.nom}
                                                                  />
                                                              </div>
                                                          </figure>
                                                      </div>
                                                  ))}
                                              </Gallery>
                                          </div>
                                      </div>
                                  ): ''}
                              </div>
                          </div>

                          <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
                              <aside className="sidebar">
                                  <FormationPageSidebar data={data} cleanHTML={cleanHTML} formationId={formationId} formationSlug={formationSlug} />
                              </aside>
                          </div>
                      </div>

                  </div>
              </div>
              {/* <!-- job-detail-outer--> */}
          </section>

          {data?.frontChapitres && data.frontChapitres.length > 0 && (
              <section className="about-section-two style-two layout-pt-60 layout-pb-60 js-tab-menu-content" style={{background: "#F7FEF6"}}>
                  <div className="auto-container">
                      <div className="row">
                          <h2 className="shadow-title left">Détails de la formation</h2>
                          <div className="row">
                              <Tabs key={data.frontChapitres.map(chap => chap.id).join()} data={data.frontChapitres} />
                          </div>
                      </div>
                  </div>
              </section>
          )}
          {pageFormationData && (
              pageFormationData?.map((formationData, formationDataIndex) => (
                  <React.Fragment key={formationDataIndex}>
                      {formationData?.frontPageContainers?.map((container, containerIndex) => (
                          <div key={containerIndex}>
                              {data?.formationsAssocie && data.formationsAssocie.length > 0 && container?.formation && (
                                  <FormationSection
                                      background={container.background_color}
                                      nbrItem = '4'
                                      container={container}
                                      formationData={data.formationsAssocie}
                                      containerIndex={containerIndex}
                                  />
                              )}
                              {container?.libre && (
                                  <Libre
                                      background={container.background_color}
                                      title={container.frontPageContents[0]?.titre}
                                      description={container.frontPageContents[0]?.description}
                                      index={container.frontPageContents[0]?.index}
                                  />

                              )}
                              {container?.demandeInformation && (
                                  <CTAPageFormation
                                      formationId={formationId}
                                      content={data}
                                  />
                              )}
                              {container?.formationsInterEntrepriseV2 && (
                                  <CalendrierSessions formationId={formationId} formationName={data.titre} background={container.background_color}/>
                              )}
                              {container?.ressources && (
                                  <React.Fragment key={`ressource-${containerIndex}`}>
                                      <Ressources
                                          background={container.background_color}
                                          title={container.titre}
                                          description={container.description}
                                      />
                                  </React.Fragment>
                              )}
                              {container?.main1 && (
                                  <React.Fragment key={`main1-${containerIndex}`}>
                                      <Main1
                                          background={container.background_color}
                                          image={container.frontPageContents[0]?.image[0]}
                                          title={container.frontPageContents[1]?.titre}
                                          description={container.frontPageContents[1]?.description}
                                          url={container.frontPageContents[1]?.url}
                                          button={container.frontPageContents[1]?.button}
                                      />
                                  </React.Fragment>
                              )}

                              {container?.main2 && (
                                  <React.Fragment key={`main2-${containerIndex}`}>
                                      <Main2
                                          background={container.background_color}
                                          image={container.frontPageContents[1]?.image[0]}
                                          title={container.frontPageContents[0]?.titre}
                                          description={container.frontPageContents[0]?.description}
                                          url={container.frontPageContents[0]?.url}
                                          button={container.frontPageContents[0]?.button}
                                      />
                                  </React.Fragment>
                              )}
                          </div>
                      ))}
                  </React.Fragment>
              ))
          )}
      </>
      )}

      {isPageLoaded && (
         <FooterDefault footerStyle="style-six alternate -type-11" />
      )}

    </>
  );
};

export default FormationSinglePage;
