import React from 'react';
import Parser from 'html-react-parser';
import Breadcrumb from "../../common/Breadcrumb";
import {motion} from "framer-motion";

function Hero8({ content, BreadcrumbVisible = false, titleBreadcrumb}) {
    return (
            <section className="banner-section-three -type-12">

                <motion.div
                    className="bg-image"
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.8}}
                >
                    {/*{content?.image[0] && <img src={content.image[0].urlS3} alt={content.image[0].alt} />}*/}

                    <figure className="main-image wow fadeInRight" data-wow-delay="1500ms">
                        {content?.image[0] ? (<img src={content.image[0].urlS3} alt={content.image[0].nom} />) : ''}
                    </figure>
                    <div className="image-voile" style={{display: "none"}}></div>
                </motion.div>

                <div className="auto-container">
                    <div className="row">
                        <div className="content-column col-lg-9 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="title-box wow fadeInUp">
                                    {!BreadcrumbVisible && <Breadcrumb meta={titleBreadcrumb} />}
                                    <h1>
                                        {typeof content.titre === 'string' ? Parser(content.titre) : ''}
                                    </h1>
                                    <div className="text">
                                        {typeof content.description === 'string' ? Parser(content.description) : ''}
                                    </div>
                                </div>
                                {content?.button ?(
                                <a href={content.url} className="theme-btn btn-style-six mt-3">
                                    {content.button}
                                </a>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Hero8;
