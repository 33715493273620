import {Link} from "react-router-dom";
import React from "react";

const FooterContent4 = () => {
  const footerContent = [
    {
      id: 1,
      title: "Qui sommes-nous ?",
      colClass: "col-lg-4",
      menuList: [
        { name: "Groupe Talents Handicap", route: "https://www.groupe-talentshandicap.com/" , target :"_blank" },
        { name: "Forums en ligne Talents Handicap", route: "https://www.talents-handicap.com/" , target :"_blank"},
        { name: "Nous contacter", route: "/contact" },
      ],
    },
    {
      id: 2,
      title: "Ressources utiles",
      colClass: "col-lg-4",
      menuList: [
        { name: "Nos solutions de sensibilisation", route: "/solutions-sensibilisation" },
        { name: "Démo Jeu de l'inclusion", route: "/start/2/talents-handicap" },
        { name: "Foire Aux Questions", route: "/resources/faq" },
        { name: "Plan du site", route: "/plan-site" },
      ],
    },
  ];
  return (
      <>
        {footerContent.map((item) => (
            <div
                className={`footer-column ${item.colClass} col-md-6 col-sm-12`}
                key={item.id}
            >
              <div className="footer-widget links-widget">
                <h4 className="widget-title">{item.title}</h4>
                <div className="widget-content">
                  <ul className="list">
                    {item?.menuList?.map((menu, i) => (
                        <li key={i}>
                          <Link to={menu.route} {...(menu.target ? {target: menu.target} : {})}>{menu.name}</Link>
                        </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
        ))}

        <div className="footer-column col-lg-4 col-md-12 col-sm-12" >
          <div className="footer-widget">
            <div className="widget-content">
              <div className="newsletter-form">
                <div className="text footer-qualiopi">
                  <img
                      width={230}
                      src="/images/logo-qualiopi-footer.webp"
                      alt="brand"
                  />
                  <div>
                    La certification qualité a été délivrée au titre
                    de la catégorie d’action suivante : <br/><span>ACTIONS DE FORMATION</span>
                  </div>
                </div>
{/*                <div className="text">Restez informé·es sur les dernières formations,</div>
                <SearchForm/>*/}
              </div>
            </div>
          </div>
        </div>

        {/* End .footer-column */}
      </>
  );
};

export default FooterContent4;
