import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";
import {Link, useParams} from "react-router-dom";
import Swal from 'sweetalert2';

function JeuInclusionAPi() {
    const { jeuId, jeuSlug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/jeu_entreprises`)
                .then((res) => res.json())
                .then((response) => {
                    setData(response);
                    window.location.replace("/start/2/talents-handicap");
                    setIsLoading(false);
                })
                .catch((error) => console.log(error));
    }, [jeuId, jeuSlug]);


    return (
            <>
                {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
                            <Skeleton count={5} height={50} width={800} baseColor="#f7f7f7" highlightColor="#f7f7f7" duration={2} enableAnimation={true} />
                        </div>
                ) : (
                        <>
                            <Page data={data}/>
                            {/*<JeuxList jeux={jeux} />*/}
                        </>
                )}
            </>
    );
}

const Page = React.memo(({ data }) => {
    const addClassToUl = (html) => {
        return html.replace(/<ul>/g, '<ul class="list-style-three">');
    };
    return (
            <section className="about-section-three">
                <div className="auto-container">
                    <div className="text-box">
                        {/*<h4>{data?.nom.charAt(0).toUpperCase() + data?.nom.slice(1).toLowerCase()}</h4>*/}
                        {/*<p dangerouslySetInnerHTML={{ __html: addClassToUl(data?.description) }} />*/}
                    </div>
                </div>
            </section>
    );
});

/*const JeuxList = React.memo(({ jeux }) => {
    return (
            <div className="sidebar-page-container pt-0" >
                <div className="auto-container">
                    <div className="row">
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="blog-grid">
                                <div className="row justify-content-center">
                                    {jeux.map((jeu, index) => (
                                        <JeuBlock key={index} jeu={jeu} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
});*/

const JeuBlock = React.memo(({ jeu }) => {
    const jeuId = `${jeu.id}`;
    const jeuSlug = `${jeu.slug}`;

    const handleClick = (e) => {
        if (!jeu.ouvert) {
            e.preventDefault();
            Swal.fire({
                icon: 'error',
                title: 'Jeu inaccessible',
                text: 'Ce jeu est actuellement fermé et inaccessible.',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
            <div className="news-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="image-box">
                       <span className={`statut ${jeu.ouvert ? 'ouvert' : 'ferme'}`}>{jeu.ouvert ? 'Ouvert' : 'Fermé'}</span>
                        <figure className="image">
                            {jeu?.vignettes?.slice(0, 1).map(img =>
                                    <img key={img.id} src={img.urlS3} alt={img.alt} />
                            )}
                        </figure>
                    </div>
                    <div className="lower-content">
                        <ul className="post-meta">
                      {/*      <li><a href="#">{jeu.commentaires.length} Comment</a></li>*/}
                        </ul>
                        <h3>{jeu.nom}</h3>
                        <Link to={`/jeu-inclusion/${jeuId}/${jeuSlug}`} className="read-more" onClick={handleClick}>Jouer <i className="fa fa-angle-right"></i></Link>
                    </div>
                </div>
            </div>
    );
});

export default JeuInclusionAPi;