import React, {useEffect, useState} from "react";
import Seo from "../components/common/Seo";
import MobileMenu from "../components/header/MobileMenu";
import Header from "../components/home-7/Header";
import PageAPi from "./page/page";
import {useLocation} from "react-router-dom";
import axios from "axios";

const Page = ({type}) => {
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/front_pages?type=${type}`);
                setPages(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [location]);
    return (
        <>
            <Seo pageTitle={pages[0]?.titre} pageDescription={pages[0]?.description} />
            <span className="header-span"></span>
            <Header/>
            <MobileMenu/>
            <PageAPi pages={pages} loading={loading}/>
        </>
    );
};


export default Page;

