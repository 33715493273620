import React from 'react';
import Parser from "html-react-parser";
import FooterDefault from "../../components/footer/common-footer";
import Main2 from "../../components/accueil/main2";
import Metrics from "../../components/accueil/metrics";
import FonctionnaliteCard from "../../components/accueil/FonctionnaliteCard";
import Main1 from "../../components/accueil/main1";
import Libre from "../../components/accueil/libre";
import FAQ from "../../components/accueil/faq";
import Hero8 from "../../components/hero/hero-7/hero";

import Sidebar1 from "../../components/accueil/sidebar1";
import { motion } from 'framer-motion';
import Sidebar2 from "../../components/accueil/sidebar2";
import Ressources from "../../components/accueil/ressources";
import JeuBlocks from "../../components/JeuBlocks";

function PageAPi({pages, loading}) {

    return (
            <>

                    {pages?.map((home, homeIndex) => (
                        <React.Fragment key={homeIndex}>
                            {home?.frontPageContainers?.map((container, containerIndex) => (
                                <div key={containerIndex}>
                                    {container?.banner && (
                                        <React.Fragment key={`banner-${containerIndex}`}>
                                            {container.frontPageContents?.map((content, contentIndex) => (
                                                <Hero8 key={contentIndex} content={content} titleBreadcrumb={pages[0]?.titre}  />
                                            ))}
                                        </React.Fragment>
                                    )}
                                    {container?.textSideBar && (
                                        <motion.section
                                                className="libre job-detail-outer"
                                                style={container.background_color ? { background: container.background_color } : {}}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                transition={{ delay: 0.5, duration: 1 }}
                                        >
                                            <div className="auto-container">
                                                <div className="row">
                                                    <React.Fragment key={`textSidebar-${containerIndex}`}>
                                                        <div className="content-container d-flex flex-wrap">
                                                            {container.frontPageContents[0] && (
                                                                <div className="libre-sidebar content-column col-lg-9 col-md-12 col-sm-12">
                                                                    <div className="d-flex justify-content-between" style={{textAlign: 'left', padding:'0px 50px 0 0'}}>
                                                                        <div className="page-left">
                                                                            {container.frontPageContents[0].image.length > 0 && (
                                                                                <img
                                                                                    src={container.frontPageContents[0].image[0].urlS3}
                                                                                    style={{borderRadius: "30px"}}
                                                                                    alt={container.frontPageContents[0].image[0].alt}
                                                                                />
                                                                            )}
                                                                            {container.frontPageContents[0].description && (
                                                                                <div className="text mt-9">
                                                                                    {Parser(container.frontPageContents[0].description)}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="sidebar-column col-lg-3 col-md-12 col-sm-12">
                                                                <aside className="sidebar">
                                                                    {container.frontPageContents?.slice(1).map((content, contentIndex) => (
                                                                        <React.Fragment key={`sidebar-${contentIndex}`}>
                                                                            {content.sideBar1 ? (
                                                                                <Sidebar1
                                                                                    image={content.image.length > 0 ? content.image[content.image.length - 1].urlS3 : null}
                                                                                    imageAlt={content.image.length > 0 ? content.image[content.image.length - 1].alt : null}
                                                                                    title={content.titre}
                                                                                    description={content.description}
                                                                                    button={content.button}
                                                                                    url={content.url}
                                                                                    index={contentIndex}
                                                                                    background={content.background_color}
                                                                                />
                                                                            ): null}
                                                                            {content.sideBar2 ? (
                                                                            <Sidebar2
                                                                                image={content.image.length > 0 ? content.image[content.image.length - 1].urlS3 : null}
                                                                                imageAlt={content.image.length > 0 ? content.image[content.image.length - 1].alt : null}
                                                                                title={content.titre}
                                                                                description={content.description}
                                                                                button={content.button}
                                                                                url={content.url}
                                                                                index={contentIndex}
                                                                                background={content.background_color}
                                                                            />
                                                                            ): null}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </aside>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            </div>
                                        </motion.section>
                                    )}


                                    {container?.metrics && (
                                        <React.Fragment key={`metrics-${containerIndex}`}>
                                            <Metrics
                                                background={container.background_color}
                                                data={container.frontPageContents}
                                            />
                                        </React.Fragment>
                                    )}

                                    {container?.faq && (
                                        <React.Fragment key={`metrics-${containerIndex}`}>
                                            <FAQ
                                                    data={container.frontPageContents}
                                                    title={container.titre}
                                                    description={container.description}
                                            />
                                        </React.Fragment>
                                    )}

                                    {container?.fonctionnalite && (
                                        <React.Fragment key={`fonctionnalite-${containerIndex}`}>
                                            {container.frontPageContents.length > 0 && (
                                            <section className="layout-pt-60 layout-pb-60" style={{ background: container.background_color }}>
                                                <div className="auto-container">
                                                    <div className="text-center">
                                                        {container.titre ? (
                                                            <h2 className="libre-title">
                                                                {Parser(container.titre)}
                                                            </h2>) : ''}
                                                        {container.description ? (<div className="text mt-9">
                                                            {Parser(container.description)}
                                                        </div>) : ''}
                                                    </div>

                                                    <FonctionnaliteCard
                                                        fonctionnalites={container.frontPageContents}
                                                        containerIndex={containerIndex}
                                                    />
                                                </div>
                                            </section>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {container?.main1 && (
                                        <React.Fragment key={`main1-${containerIndex}`}>
                                            <Main1
                                                background={container.background_color}
                                                image={container.frontPageContents[0]?.image[0]}
                                                title={container.frontPageContents[1]?.titre}
                                                description={container.frontPageContents[1]?.description}
                                                url={container.frontPageContents[1]?.url}
                                                button={container.frontPageContents[1]?.button}
                                            />
                                        </React.Fragment>
                                    )}

                                    {container?.main2 && (
                                        <React.Fragment key={`main2-${containerIndex}`}>
                                            <Main2
                                                background={container.background_color}
                                                image={container.frontPageContents[1]?.image[0]}
                                                title={container.frontPageContents[0]?.titre}
                                                description={container.frontPageContents[0]?.description}
                                                url={container.frontPageContents[0]?.url}
                                                button={container.frontPageContents[0]?.button}
                                            />
                                        </React.Fragment>
                                    )}

                                    {container?.libre && (
                                        <React.Fragment key={`libre-${containerIndex}`}>
                                            <Libre
                                                background={container.background_color}
                                                title={container.frontPageContents[0]?.titre}
                                                description={container.frontPageContents[0]?.description}
                                                index={container.frontPageContents[0]?.index}
                                            />
                                        </React.Fragment>
                                    )}
                                    {container?.ressources && (
                                        <React.Fragment key={`ressource-${containerIndex}`}>
                                            <Ressources
                                                background={container.background_color}
                                                title={container.titre}
                                                description={container.description}
                                            />
                                        </React.Fragment>
                                    )}
                                    {container?.jeu && (
                                        <React.Fragment key={`jeu-${containerIndex}`}>
                                            <JeuBlocks description={container.description} title={container.titre} entrepriseId={2} />
                                        </React.Fragment>
                                    )}
                                </div>
                            ))}
                        </React.Fragment>
                    ))}

                {!loading && <FooterDefault footerStyle="style-six alternate -type-11"/>}
            </>
    );
};

export default PageAPi;