import React from 'react';
import Parser from 'html-react-parser';

function Ressources({ title, description, background }) {
    const bgStyle = background ? { background } : {};
    return (
            <section className="layout-pt-60 layout-pb-60" style={bgStyle}>
                <div className="auto-container">
                    <div className="row justify-content-between align-items-end">
                        {title && (<h2 className="shadow-title fw-700">{typeof title === 'string' ? Parser(title) : ''}</h2>)}
                        {description && (<div className="text mt-9 text-center">{typeof description === 'string' ? Parser(description) : ''}</div>)}
                    </div>

                    <div className="row wow fadeInUp mt-5">

                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="feature-block">
                                <div className="inner-box">
                                    <figure className="image"><img src="/images/index-15/cities/1.png" alt="" /></figure>
                                    <div className="overlay-box">
                                        <div className="content">
                                            <h5>Guides</h5>
                                            <span className="total-jobs">Les bonnes pratiques d’inclusion</span>
                                            <a href="#"  className="overlay-link"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="feature-block">
                                <div className="inner-box">
                                    <figure className="image"><img src="/images/index-15/cities/2.png" alt="" /></figure>
                                    <div className="overlay-box">
                                        <div className="content">
                                            <h5>Outils</h5>
                                            <span className="total-jobs">Les bonnes pratiques d’inclusion</span>
                                            <a href="#" className="overlay-link"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="feature-block">
                                <div className="inner-box">
                                    <figure className="image"><img src="/images/index-15/cities/3.png" alt="" /></figure>
                                    <div className="overlay-box">
                                        <div className="content">
                                            <h5>Livret</h5>
                                            <span className="total-jobs">Les bonnes pratiques d’inclusion</span>
                                            <a href="#" className="overlay-link"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="feature-block">
                                <div className="inner-box">
                                    <figure className="image"><img src="/images/index-15/cities/4.png" alt="" /></figure>
                                    <div className="overlay-box">
                                        <div className="content">
                                            <h5>Guides</h5>
                                            <span className="total-jobs">Les bonnes pratiques d’inclusion</span>
                                            <a href="#"  className="overlay-link"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
}

export default Ressources;
