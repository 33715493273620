import React, { useEffect, useState } from 'react';
import {Link, useParams} from "react-router-dom";
import Seo from "../../components/common/Seo";
import Header from "../../components/home-7/Header";
import MobileMenu from "../../components/header/MobileMenu";
import Parser from "html-react-parser";
import FooterDefault from "../../components/footer/common-footer";
import axios from "axios";
import Swal from "sweetalert2";
import {htmlToText} from "html-to-text";
import Hero7 from "../../components/hero/hero-7";
import Sidebar1 from "../../components/accueil/sidebar1";
import Sidebar2 from "../../components/accueil/sidebar2";
import {motion} from "framer-motion";
import JeuBlocks from "../../components/JeuBlocks";
import ModalJeu from "../../components/form/ModalJeu";

function EntreprisePageAPi() {
    const { entrepriseId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [page, setPage] = useState();

    const [jeux, setJeux] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    useEffect(() => {
        const loadTimeout = setTimeout(() => {
            setIsPageLoaded(true);
        }, 2000);

        return () => clearTimeout(loadTimeout);
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/jeu_entreprises/${entrepriseId}`)
                .then((res) => res.json())
                .then((response) => {
                    setPage(response);
                    if (response.erreur === 1) {
                        window.location.replace("/");
                    }
                    setIsLoading(false);
                })
                .catch((error) => console.log(error));
    }, [entrepriseId]);

    useEffect(() => {
        const fetchJeuxData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/jeu_programmes?jeu_entreprise=${entrepriseId}&statut=1&`);
                setJeux(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchJeuxData();
    }, [entrepriseId]);

    useEffect(() => {
        if (!isLoading && isPageLoaded) {
            const links = document.querySelectorAll('a[href="/jeu-inclusion/equipe"]');
            links.forEach(link => {
                link.addEventListener('click', handleShowModal);
                link.removeAttribute('href');
            });

            return () => {
                links.forEach(link => {
                    link.removeEventListener('click', handleShowModal);
                });
            };
        }
    }, [isLoading, isPageLoaded]);


    const seoPageDescription = (html) => {
        return htmlToText(html).slice(0, 150);
    };
    return (
            <>
                <Seo
                    pageTitle={
                        (page?.nom || '')
                            .charAt(0)
                            .toUpperCase() + (page?.nom || '')
                            .slice(1)
                            .toLowerCase()
                    }
                    pageDescription={
                        page && page.description
                            ? seoPageDescription(page.description)
                            : ''
                    }
                />

                {/* <!-- Header Span --> */}
                <span className="header-span"></span>

                <Header />
                {/* <!--End Main Header --> */}

                <MobileMenu />
                {isPageLoaded && (
                    <>
                        <div>
                            <Hero7 key={page.id}
                                   titre={page?.nom}
                                   description={page?.introduction}
                                   image={page?.oneHeader?.lien}
                                   nomImage={page?.oneHeader?.nom}
                                   filArian={false}
                            />
                            <motion.section
                                className="libre job-detail-outer jeu"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{delay: 0.5, duration: 1}}
                            >
                                <div className="auto-container">
                                    <div className="row">
                                        <div className="content-container d-flex flex-wrap">

                                            <div
                                                className="libre-sidebar content-column col-lg-9 col-md-12 col-sm-12">
                                                <div className="d-flex justify-content-between"
                                                     style={{textAlign: 'left', padding: '0px 50px 0 0'}}>
                                                    <div className="page-left">
                                                        {page?.description && (
                                                            <div className="text mt-9">
                                                                <p>{Parser(page?.description)}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="sidebar-column col-lg-3 col-md-12 col-sm-12">
                                                <aside className="sidebar">
                                                    <Sidebar1
                                                        image={page?.oneLogo.lien ? page?.oneLogo.lien : null}
                                                        imageAlt={page?.oneLogo.nom ? page?.oneLogo.nom : null}
                                                        title="Engagez-vous !"
                                                        description={page?.descriptionSideBar1}
                                                        background="#F7FEF6"
                                                    />

                                                    <Sidebar2
                                                        image={page?.imageSidebar.lien ? page?.imageSidebar.lien : null}
                                                        imageAlt={page?.imageSidebar.nom ? page?.imageSidebar.nom : null}
                                                        title="Guide d'utilisation "
                                                        description={page?.descriptionSideBar2}
                                                        guide={true}
                                                    />
                                                </aside>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.section>
                            <JeuBlocks entrepriseId={entrepriseId} />
                            {/* <!-- End Job Detail Section --> */}

                            {isPageLoaded && (
                                <FooterDefault footerStyle="style-six alternate -type-11"/>
                            )}
                        </div>
                    </>
                )}

                <ModalJeu jeuId={entrepriseId} handleClose={handleCloseModal} show={showModal} newCode={true}/>
            </>
    );
}

const Page = React.memo(({page}) => {
    if (!page) return null; // Early return if page is not loaded yet

    const addClassToUl = (html) => {
        return html.replace(/<ul>/g, '<ul class="list-style-three">');
    };

    const description = page?.description?.toString() || '';
    const parsedDescription = Parser(addClassToUl(description));

    const pageTitle = page?.nom ? page.nom.charAt(0).toUpperCase() + page.nom.slice(1).toLowerCase() : '';

    return (
        <section className="about-section-three">
            <div className="auto-container">
                <div className="text-box">
                    {parsedDescription}
                </div>
            </div>
        </section>
    );
});

const JeuxList = React.memo(({jeux}) => {
    if (!jeux.length) return null; // Early return if no games are available

    return (
        <div className="sidebar-page-container">
            <div className="auto-container">
                <div className="row">
                    <div className="content-side col-lg-12 col-md-12 col-sm-12">
                        <div className="blog-grid">
                            <div className="row justify-content-center">
                                {jeux.map((jeu, index) => (
                                    jeu.iframeJeu && jeu.iframeJeu.trim() !== '' && <JeuBlock key={index} jeu={jeu}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const JeuBlock = React.memo(({jeu}) => {
    if (!jeu) return null;

    const jeuId = `${jeu.id}`;
    const jeuSlug = `${jeu.slug}`;

    const handleClick = (e) => {
        if (!jeu.ouvert) {
            e.preventDefault();
            Swal.fire({
                icon: 'error',
                title: 'Jeu inaccessible',
                text: 'Ce jeu est actuellement fermé et inaccessible.',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <div className="news-block col-lg-4 col-md-6 col-sm-12">
            <div className="inner-box">
                <div className="image-box">
                    <span
                        className={`statut ${jeu.ouvert ? 'ouvert' : 'ferme'}`}>{jeu.ouvert ? 'Ouvert' : 'Fermé'}</span>
                    <figure className="image">
                        {jeu?.vignettes?.slice(0, 1).map(img => (
                            <img key={img.id} src={img.urlS3} alt={img.alt}/>
                        ))}
                    </figure>
                </div>
                <div className="lower-content">
                    <h3>{jeu.nom}</h3>
                    <Link to={`/jeu-inclusion/${jeuId}/${jeuSlug}`} className="read-more" onClick={handleClick}>Jouer <i
                        className="fa fa-angle-right"></i></Link>
                </div>
            </div>
        </div>
    );
});

export default EntreprisePageAPi;