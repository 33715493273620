import React, { useState } from 'react';
import Parser from "html-react-parser";

const Tabs = ({ data }) => {
    const filteredData = data.filter(chapitre => chapitre.description);
    const [activeTab, setActiveTab] = useState(0);

    return (
            <div className="default-tabs style-two tabs-box">
                <ul className="tab-buttons clearfix">
                    {filteredData.map((chapitre, index) => (
                            <li
                                    key={index}
                                    className={`tab-btn ${activeTab === index ? 'active-btn' : ''}`}
                                    onClick={() => setActiveTab(index)}
                            >
                                {chapitre.titre}
                            </li>
                    ))}
                </ul>

                <div className="tabs-content libre">
                    {filteredData.map((chapitre, index) => (
                            <div
                                    key={index}
                                    className={`tab ${activeTab === index ? 'active-tab' : ''}`}
                                    id={`tab${index + 4}`}
                            >
                                <p>{Parser(chapitre.description)}</p>
                            </div>
                    ))}
                </div>
            </div>
    );
};

export default Tabs;